<template>
    <div class="card">
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
            <div class="m-2">
                <b-row class="mb-1">
                    <b-col cols="12" md="6"            
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h4 class="table_title" style="padding:0px;">University List</h4>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <v-select
                            v-model="perPage"
                            style="width: 90px"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>
                </b-row>
            </div>
            <b-table
                striped
                hover
                responsive
                show-empty
                v-model="applicationTable"
                empty-text="No matching records found"
                :per-page="perPage"
                :current-page="currentPage"
                :items="documentList"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="position-relative promotionalNotification informationdoc"
            >
                <template #cell(id)="data">
                    {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                </template>
                <template #cell(title)="data">
                    <div>
                        {{data.item.name}}
                    </div>
                </template>

                <template #cell(createdAt)="data">
                        {{data.item.updatedAt ? dateFormat(data.item.updatedAt) : dateFormat(null)}}
                </template>
                <template #cell(action)="data" v-if="!onlyRead">
                    <div class="d-flex align-items-center">
                        <img src='@/assets/images/erflog/Edit.png' :disabled="onlyRead" class="action_icon" @click.prevent="selectedEditDoc = {...data.item},$bvModal.show('edit-field-model')"/>
                        <img src='@/assets/images/erflog/Delete.png' :disabled="onlyRead" v-if="(data.item.assigneeStudent && data.item.assigneeStudent.length == 0) || data.item.assigneeStudent == undefined" class="action_icon" @click.prevent="selectedDeleteDoc = data.item,$bvModal.show('delete-edfield-document')"/>
                    </div>
                </template>
                <template #cell(action)="data" v-else>
                    <div class="d-flex align-items-center">
                        <img src='@/assets/images/erflog/Edit.png' style="opacity: 0.5;cursor: pointer;" :disabled="onlyRead" class="action_icon"/>
                        <img src='@/assets/images/erflog/Delete.png' style="opacity: 0.5;cursor: pointer;" :disabled="onlyRead" v-if="(data.item.assigneeStudent && data.item.assigneeStudent.length == 0) || data.item.assigneeStudent == undefined" class="action_icon"/>
                    </div>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <div class="row">
                    <b-col md="6" class="d-flex align-items-center">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ perPage }} of {{ documentList.length }} entries</span>
                        <!-- <span class="text-muted mt-1">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span> -->
                    </b-col>
                    <b-col  md="6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="documentList.length"
                            :per-page="perPage"
                            align="right"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0 custom_pagination"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </div>
            </div>
            <b-modal
                id="delete-edfield-document"
                cancel-variant="outline-secondary"
                ok-title="Yes"
                cancel-title="No"
                centered
                title=""
                :no-close-on-backdrop="true"
                @hidden="selectedDeleteDoc = null"
                @cancel='isSpiner=false'
                @ok="deleteField()"
            >
                <div class="create_account_img">
                <img src="@/assets/images/erflog/DeletePopup.png" alt="">
                </div>
                <div style="text-align: center;" v-if="selectedDeleteDoc!=''">
                <label class="s_modal_title">Delete Field</label>
                <p>Are you sure you want to delete this field?</p>
                </div>
            </b-modal>
            <!-- Create College -->
            <b-modal
                id="create-education-field"
                cancel-variant="outline-secondary"
                ok-title="Save"
                cancel-title="Cancel"
                centered
                size="lg"
                title="Create University"
                :no-close-on-backdrop="true"
                @hidden="selectedCollege='',resetData()"
                @ok="(e)=>{e.preventDefault();createNewCollege(e);}" 
                :cancel-disabled='modalLoader'
                :ok-disabled="modalLoader"      
            >
                <validation-observer ref="personalInfoForm">
                <!-- form -->
                <b-form>
                    <b-spinner variant="primary" v-if="modalLoader" large class="pageLoadSpinners"/>
                    <b-row>
                    <!-- Name -->
                    <b-col md="12">
                        <b-form-group label="University Name">
                        <validation-provider
                            #default="{ errors }"
                            name="university name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="collegeName"
                                :state="errors.length > 0 ? false:null"
                                :disabled="modalLoader"
                                placeholder="Enter Text"
                                maxlength=100
                                @blur="collegeName=collegeName.trim()"
                            />
                            <small class="text-danger" v-if="!duplicateCollegeName">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>College already exists.</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    </b-row>
                </b-form>
                </validation-observer>
            </b-modal>
            <b-modal
                id="edit-field-model"
                cancel-variant="outline-secondary"
                ok-title="Save"
                cancel-title="Cancel"
                centered
                size="lg"
                title="Edit University"
                :no-close-on-backdrop="true"
                @hidden="selectedCollege='',resetData2()"
                @ok="(e)=>{e.preventDefault();editField(e);}" 
                :cancel-disabled='modalLoader'
                :ok-disabled="modalLoader"      
            >
                <validation-observer ref="editFieldForm">
                <!-- form -->
                <b-form>
                    <b-spinner variant="primary" v-if="modalLoader" large class="pageLoadSpinners"/>
                    <b-row>
                    <!-- Name -->
                    <b-col md="12">
                        <b-form-group label="University Field Name">
                        <validation-provider
                            #default="{ errors }"
                            name="university field name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="selectedEditDoc.name"
                                :state="errors.length > 0 ? false:null"
                                :disabled="modalLoader"
                                placeholder="Enter Text"
                                maxlength=100
                                @blur="selectedEditDoc.name=selectedEditDoc.name.trim()"
                            />
                            <small class="text-danger" v-if="!duplicateCollegeName">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Field already exists.</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    </b-row>
                </b-form>
                </validation-observer>
            </b-modal>
        </div>
    </div>
</template>

<script>
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,
  BCardText,BSpinner,
  BTable,BPagination, BInputGroup
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules';
import firebaseTimeStamp from "firebase";

extend('required',required)
import moment from 'moment';
export default {
   components: {
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BSpinner,
        BTable,BPagination, BInputGroup,
        vSelect,
        Ripple,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50],
            applicationTable:[],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            isSpinner:false,
            filterOn: [],
            selectedDeleteDoc:null,
            selectedEditDoc:null,
            fields: [
                {
                    key: 'title', label: 'Name',sortable: true
                },
                {
                    key: 'createdAt', label: 'Time',sortable: true
                }, 
                {
                    key: 'action',label: 'Action',sortable: false
                }
                // {
                //     key: 'attachmentName', label: 'Attachment',sortable: true
                // }, 
                // {
                //     key: 'action',label: 'Action',sortable: false
                // }
            ],
            documentList:[],
            onlyRead:false,
            dataArr:[],
            modalLoader:false,
            collegeName:'',
            duplicateCollegeName:false,
            snapshotListener: null,
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        resetData(){
            var self=this
            self.collegeName=''
        },
        resetData2(){
            var self=this
            self.selectedEditDoc=null
        },
        dateFormat(data) {
            if(data==null || data==undefined || data=='' || data=='Invalid date'){
                return moment(new Date()).format("DD-MM-YYYY HH:mm")
            } else {
                return moment(new Date(data.seconds * 1000)).format("DD-MM-YYYY HH:mm")
            }
        },
        deleteField(){
            db.collection(dbCollections.UNIVERSITIESFIELDS).doc(this.selectedDeleteDoc.id).update({
                isDeleted:true,
                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }).then(()=>{
                this.isSpinner=false;
                this.$bvModal.hide('delete-edfield-document')
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Education field deleted successfully',
                        icon: 'CoffeeIcon',
                        variant: 'success',                               
                    },
                })
            }).catch(()=>{
                this.isSpinner=false;
                this.$bvModal.hide('delete-edfield-document')
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Education field deleted failed',
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    }
                })
            })
        },
        createCollege(Unidata,cb){
            let docRef;
            if(Unidata.id) {
                docRef = db.collection(dbCollections.UNIVERSITIESFIELDS).doc(Unidata.id);
            } else {
                docRef = db.collection(dbCollections.UNIVERSITIESFIELDS).doc();
                Unidata.id = docRef.id;
            }
            docRef.set(Unidata,{ merge: true }).then(()=>{
                cb({status:true})
            }).catch(()=>{
                cb({status:false,message:'Something went wrong'})
            })
        },
        createNewCollege(e){
            var self = this
            if(self.modalLoader == false){
                self.$refs.personalInfoForm.validate().then(success=>{
                    if(success && !self.duplicateCollegeName) {
                        this.modalLoader = true;
                        let keyVal;
                        if(self.collegeName.split(' ').length > 1){
                            keyVal = self.collegeName.split(' ').join('_').toLowerCase()
                        } else {
                            keyVal = self.collegeName.toLowerCase()
                        }
                        var Unidata={
                            name:self.collegeName,
                            isDeleted:false,
                            createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            key:keyVal
                        }
                        self.createCollege(Unidata,res=>{
                            if(res.status == true) {
                                this.modalLoader = false
                                self.$bvModal.hide('create-education-field')
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'Education field created successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                                })
                                self.resetData()
                            } else {
                                this.modalLoader = false
                                self.$bvModal.hide('create-education-field')
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: res.message,
                                        icon: 'CoffeeIcon',
                                        variant: 'danger',                               
                                    }
                                })
                                self.resetData()
                            }
                        })
                    } else {
                        this.modalLoader = false
                        e.preventDefault()
                    }
                });
            }
        },
        editField() {
            let self = this;
            self.$refs.editFieldForm.validate().then(success=>{
                if(success && !self.duplicateCollegeName) {
                    this.modalLoader = true;
                    let keyVal;
                    if(self.selectedEditDoc.name.split(' ').length > 1){
                        keyVal = self.selectedEditDoc.name.split(' ').join('_').toLowerCase()
                    } else {
                        keyVal = self.selectedEditDoc.name.toLowerCase()
                    }
                    var Unidata={
                        name:self.selectedEditDoc.name,
                        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                        key:keyVal,
                        id:self.selectedEditDoc.id,
                    }
                    self.createCollege(Unidata,res=>{
                        if(res.status == true) {
                            let docRef = db.collection(dbCollections.FIELDVALUE).doc()
                            let Unidata2={
                                key:'universityValue',
                                fiedlId:Unidata.id,
                                createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                id:docRef.id,
                            }
                            docRef.set(Unidata2,{ merge: true }).then(()=>{});
                            this.modalLoader = false
                            self.$bvModal.hide('edit-field-model')
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Education field updated successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',                               
                                },
                            })
                            self.resetData()
                        } else {
                            this.modalLoader = false
                            self.$bvModal.hide('edit-field-model')
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: res.message,
                                    icon: 'CoffeeIcon',
                                    variant: 'danger',                               
                                }
                            })
                            self.resetData()
                        }
                    })
                } else {
                    this.modalLoader = false
                    e.preventDefault()
                }
            });
        }
    },
    computed: {
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.documentList.length,
            }
        },
    },
    beforeDestroy() {
        this.$root.$off('saveNewField')
        if(this.snapshotListener !== null) {
			this.snapshotListener();
		}
    },
    created() {
        var self = this;
        self.$root.$on('saveNewField',()=>{
            self.$bvModal.show('create-education-field')
        })
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Education Fields Management"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
        try {     
            if(self.snapshotListener !== null) {
                self.snapshotListener();
            }
            this.isSpinner = true;
            self.snapshotListener = db.collection(dbCollections.UNIVERSITIESFIELDS).where("isDeleted",'==',false)
                .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                if (snapshot.empty) {
                    this.isSpinner = false;
                    // console.error(snapshot);
                    self.documentList = [];
                    return;
                }
                snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                    if(change.type === "added") {
                        let index = self.documentList.findIndex((ele)=>{
                            return ele.id == change.doc.data().id
                        })
                        if(index > -1) {
                            let obj = {...change.doc.data()};
                            self.documentList[index] = obj
                        } else {
                            if(!change.doc.metadata.hasPendingWrites) {
                                let obj = {...change.doc.data()};
                                self.documentList.push(obj);
                            }
                        }
                    }
                    if(change.type === "modified") {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList[index] = {...change.doc.data()};
                        } 
                        else {
                            let obj = {...change.doc.data()};
                            self.documentList.push({...obj});
                        }
                    }
                    if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList.splice(index, 1);
                        }
                    }
                })
                self.documentList.sort((a,b)=>{
                    return a.createdAt.seconds - b.createdAt.seconds
                })
                self.$nextTick(()=>{
                    self.documentList = self.documentList.filter((ele)=>{
                        return ele.isDeleted == false
                    })
                    self.$forceUpdate();
                    self.isSpinner = false
                })
            })
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
        }
    },
}
</script>